<template>
  <v-col cols="12">
    <div v-if="type !== 'map'">
      <v-card
        v-for="hotel in hotels.Hotels"
        :key="hotel.HotelIndex"
        class="mb-5 d-none d-sm-block"
      >
        <div class="d-flex flex-no-wrap">
          <v-row>
            <v-col cols="3">
          <div class="image-div">
            <span>
              <v-img :alt="hotel.HotelName" class="image" :src="hotel.HotelPicture || require(`@/assets/imagefail.png`)"/>
            </span>
          </div>
            </v-col>
            <v-col cols="5">
              <div>
                <v-card-title class="headline flex-md-shrink-1">
                  {{hotel.HotelName}}
                </v-card-title>
                <v-card-subtitle>
                  {{hotel.HotelAddress}}
                  <v-btn text color="primary" small @click="openMap(hotel.HotelIndex, hotel.HotelOnMap)">
                    <v-icon>mdi-map-marker-radius</v-icon>Show on map
                  </v-btn>
                </v-card-subtitle>
                <div>
                  <v-rating class="ml-3 mb-2" size="25" :length="hotel.stars" color="warning" readonly large dense  :value="hotel.stars" full-icon="mdi-star"></v-rating>
                  <p  class="body-2 ml-3">{{hotel.HotelDescription}}</p>
                </div>
                <v-row v-if="hotel.TripAdviserUrl">
                  <v-avatar color="indigo" size="36">
                    <span class="white--text headline">{{hotel.TripAdviserRating}}</span>
                  </v-avatar>
                </v-row>
              </div>
            </v-col>
              <v-col cols="12" md="4" class="text-center py-5 d-flex justify-space-around">
                  <v-divider class="d-none d-md-block" vertical></v-divider>
                  <div>
                    <v-card-text>{{getNights()}} Nights, {{hotels.roomGuests.Adults + hotels.roomGuests.Children}} Guests/room</v-card-text>
                    <span class="grey--text mb-3">price per person(Adult) (incl. taxes & fees)</span>
                    <p class="display-1 font-weight-bold primary--text">${{hotel.pricingInfo.totalPrice.toFixed(2)}}</p>
                    <v-btn
                      tile
                      color="primary"
                      @click="select(hotel.HotelIndex, hotel.HotelCode)"
                    >
                      View Details
                      <v-icon small right color="white">mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
              </v-col>
          </v-row>
        </div>
      </v-card>

      <v-dialog
        v-model="dialog1"
        max-width="80%"
      >
<!--        <GmapMap-->
<!--          :center='center'-->
<!--          :zoom='8'-->
<!--          style='width:100%;  height: 400px;'-->
<!--        >-->
<!--          <GmapMarker-->
<!--            :key="index"-->
<!--            v-for="(m, index) in markers"-->
<!--            :position="m.position"-->
<!--            @click="center = m.position"-->
<!--          />-->
<!--        </GmapMap>-->
        <GmapMap
          :center="center"
          :zoom="13"
          map-type-id="terrain"
          style="width: 1000px; height: 500px"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center=m.position"
          />
        </GmapMap>
      </v-dialog>

      <v-card
        class="mx-auto my-5 d-block d-sm-none"
        v-for="hotel in hotels.Hotels"
        :key="hotel.HotelCode"
      >

                <span>
                    <v-img height="200" width="100%" :alt="hotel.HotelName" :src="hotel.HotelPicture || require(`@/assets/imagefail.png`)"/>
                </span>

        <v-card-title class="font-weight-bolder">{{hotel.HotelName}}</v-card-title>

        <v-card-text>
          <v-row
            align="center"
            class="mx-0"
          >
            <v-rating
              :value="hotel.stars"
              color="warning"
              readonly
              small
              :length="hotel.stars"
            ></v-rating>

            <div v-if="hotel.TripAdviserUrl">
              <v-avatar color="indigo" size="36">
                <span class="white--text headline">{{hotel.TripAdviserRating}}</span>
              </v-avatar>
            </div>
          </v-row>

          <div class="my-4 subtitle-1">
            {{hotel.HotelAddress}}
            <v-btn text color="blue" small @click="openMap(hotel.HotelIndex, hotel.HotelOnMap)">
              Show on map
            </v-btn>
          </div>

          <div>{{hotel.HotelDescription}}</div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-title class="font-weight-bold">${{hotel.pricingInfo.totalPrice.toFixed(2)}}</v-card-title>

        <v-card-text>
          {{getNights()}}Nights, {{hotels.roomGuests.Adults + hotels.roomGuests.Children}} Guests/room
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            color="blueDark"
            @click="select(hotel.HotelIndex, hotel.HotelCode, $store.state.hotelSearch.guestsInfo.length)"
          >
            See availability
            <v-icon small right color="white">mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <v-card v-if="type === 'map' && hotel">
            <span>
                <v-img v-if="hotel && hotel.HotelName" height="200" width="100%" :alt="hotel.HotelName" :src="hotel.HotelPicture || require(`@/assets/imagefail.png`)"/>
            </span>

      <v-card-title class="font-weight-bolder" v-if="hotel && hotel.HotelName">{{hotel.HotelName}}</v-card-title>

      <v-card-text>
        <v-row
          align="center"
          class="mx-0"
        >
          <v-rating
            :value="hotel.stars"
            color="warning"
            readonly
            small
            :length="hotel.stars"
          ></v-rating>

          <div v-if="hotel.TripAdviserUrl">
            <v-avatar color="indigo" size="36">
              <span class="white--text headline">{{hotel.TripAdviserRating}}</span>
            </v-avatar>
          </div>
        </v-row>

        <div>{{hotel.HotelDescription}}</div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title class="font-weight-bold">${{hotel.pricingInfo.totalPrice.toFixed(2)}}</v-card-title>

      <v-card-text>
        {{getNights()}}Nights, {{hotels.roomGuests.Adults + hotels.roomGuests.Children}} Guests/room
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          tile
          color="blueDark"
          @click="select(hotel.HotelIndex, hotel.HotelCode, $store.state.hotelSearch.guestsInfo.length)"
        >
          See availability
          <v-icon small right color="white">mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: ['hotels', 'session', 'type', 'hotel'],
  data () {
    return {
      //   hotels: null,
      showPlaceholderImage: false,
      src: '',
      center: {
        lat: 0,
        lng: 0
      },
      markers: [],
      dialog: false,
      dialog1: false,
      path: [],
      now: null
    }
  },
  watch: {
    'this.$store.state.hotels' (newVal) {
      this.hotels = newVal
    },
    hotels () {
      this.now = Date.now()
    }
  },
  methods: {
    openMap (index, position) {
      this.dialog1 = !this.dialog1
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    getPosition (position) {
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    getNights () {
      const checkIn = new Date(localStorage.getItem('checkInDate'))
      const checkOut = new Date(localStorage.getItem('checkOutDate'))
      const DifferenceInTime = checkOut.getTime() - checkIn.getTime()
      return DifferenceInTime / (1000 * 3600 * 24)
    },
    select (index, code, rooms) {
      //   this.$router.push({ name: 'oneHotel', params: { sessionId: this.session, hotelIndex: index, hotelCode: code, roomsNo: rooms } })
      const spentTime = Date.now() - this.now
      const timer = (20 * 60 * 1000) - spentTime
      localStorage.setItem('hotelsTimer', timer)
      this.$router.push({ name: 'oneHotel', params: { sessionId: this.session, hotelIndex: index, hotelCode: code, roomsNo: rooms } })
    }
  },
  created () {
    this.now = Date.now()
  }
}
</script>

<style>
.image-div {
  padding: 10px;
  max-height: 100%;
}
.image-div span {
  height: 100%;
}
.image {
  height: 200px;
  width: 200px;
}
</style>
