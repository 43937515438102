<template>
  <div>
    <overlay v-if="$store.state.hotelTimeOut && hotels" @newSearch="getHotels"/>
    <v-snackbar v-model="snackbar" top :timeout="5000" :color="color">
      {{ text }}
      <v-btn icon @click="snackbar = false">
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <div class="background d-none d-md-block">
      <v-container>
        <v-row justify="center" align="center" class="ma-5">
          <hotelSearch @addHotels="getHotels"></hotelSearch>
        </v-row>
      </v-container>
    </div>
    <v-container fluid>
      <v-row justify="center" align="center" style="height: 60vh" v-if="!hotels && loaded">
        <div>
          <v-img max-width="200" class="blink-2" :src="require(`@/assets/logo.png`)"></v-img>
        </div>
      </v-row>
      <v-row v-if="hotels && !loaded">
        <v-col cols="12" sm="3" md="3">
          <hotelFilters @changed="filter"/>
        </v-col>
        <v-col v-if="hotels.Hotels" cols="12" :sm="hotels.Hotels.length > 1 ? '9' : '12'" :md="hotels.Hotels.length >= 1 ? '9' : '12'">
          <v-row justify="space-between" class="mb-3 mt-1 mx-3">
            <p class="headline font-weight-medium primary--text mb-0" v-cloak>{{ hotels.Hotels ? hotels.Hotels.length : '0' }} Hotels
              found</p>
            <v-btn color="primary" @click="prepareMaps" :loading="mapLoad">Show on map</v-btn>
          </v-row>
          <hotelsCard :hotels="hotels" :session="hotels.session_id"></hotelsCard>
        </v-col>
        <v-col v-else>
          <p>No hotels found</p>
        </v-col>
      </v-row>
      <p v-if="!hotels && !loaded" class="body-1 font-weight-bold primary--text text-center mt-5">Sorry, No hotels match
        your search .</p>
    </v-container>
    <v-dialog
      v-model="mapDialog"
      max-width="95%"
    >
      <GmapMap
        :center='center'
        :zoom='12'
        style='width:1000px;  height: 500px;'
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          @click="center = m.position"
        />
        <GmapPolyline v-bind:path.sync="path" v-bind:options="{ strokeColor:'#FF8F95'}" />
      </GmapMap>
    </v-dialog>
  </div>
</template>

<script>
import hotelsCard from '../../components/stays/hotelCard'
import hotelSearch from '../../components/stays/searchForm'
import overlay from '../../components/stays/overlay'
import hotelFilters from '../../components/stays/hotelFilters'
import { searchHotels, headersNoAuth } from '../../links'

export default {
  title: '-Hotels Search Results',
  components: {
    hotelsCard,
    hotelSearch,
    overlay,
    hotelFilters
  },
  data () {
    return {
      hotelsNum: 0,
      path: [],
      city: '',
      hotels: null,
      snackbar: false,
      color: '',
      text: '',
      loaded: true,
      markers: [],
      mapLoad: false,
      mapDialog: false,
      center: {
        lat: 0,
        lng: 0
      },
      map: null,
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      hotel: null
    }
  },
  methods: {
    prepareMaps () {
      this.mapLoad = true
      this.hotels.Hotels.forEach((hotel, i) => {
        this.markers.push({
          hotel: hotel,
          position: {
            lat: parseFloat(hotel.HotelOnMap.Latitude),
            lng: parseFloat(hotel.HotelOnMap.Longitude)
          }
        })
      })
      this.center = this.markers[0].position
      this.mapDialog = true
      this.mapLoad = false
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position
      this.hotel = marker.hotel
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    filter (hotels) {
      this.hotels.Hotels = [...hotels]
    },
    getHotels (term) {
      this.loaded = true
      this.$http.get(searchHotels(term), { headers: headersNoAuth() }).then(response => {
        if (response.status === false) {
          this.snackbar = true
          this.color = 'error'
          this.text = response.data.message
          this.loaded = false
        } else {
          this.loaded = false
          this.$store.dispatch('setHotels', response.data.data)
          this.$store.dispatch('setHotelTimeOut', false)
          this.$store.dispatch('setHotelSearchTimeoutFunction', setTimeout(() => {
            this.$store.dispatch('setHotelTimeOut', true)
          }, 20 * 60 * 1000)
          )
          localStorage.setItem('hotelsTerm', term)
          this.city = this.$store.state.hotelSearch.destination
          this.hotels = this.$store.state.hotels
        }
      }, () => {
        this.loaded = false
      })
    }
  },
  computed: {
    hotelStore () {
      return this.$store.state.hotels
    },
    cityInStore () {
      return this.$store.state.hotelSearch.destination
    }
  },
  watch: {
    hotelStore (newVal) {
      this.hotels = newVal
    },
    cityInStore (newVal) {
      this.city = newVal
    }
  },
  created () {
    window.scrollTo(0, 0)
    let term
    if (this.$route.params.term) {
      term = this.$route.params.term
    } else {
      term = localStorage.getItem('hotelsTerm')
    }
    this.getHotels(term)
  }
}
</script>

<style scoped>
.background {
  height: auto;
  min-height: fit-content;
  background: linear-gradient(#ff8f95d3, #ff8f95d3), url('../../assets/images/sea.jpg');
  background-color: deeppink;
  background-size: 100% 100%;
  background-position: center center;
}
.background .container {
  padding-top: 25px;
}

.search-bg .container {
  padding-top: 25px;
}

[v-cloack] {
  display: none;
}

.blink-2 {
  -webkit-animation: blink-2 1.5s infinite both;
  animation: blink-2 1.5s infinite both;
}

@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
</style>
